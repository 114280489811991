var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c("section", { staticClass: "section" }, [
      _vm.soundkit
        ? _c("div", { staticClass: "container grid grid__oneFourth h-mb-80" }, [
            _c("div", { staticClass: "h-textCenter" }, [
              _c("img", {
                staticClass: "image",
                attrs: { src: _vm.soundkit.image_url },
              }),
              _c(
                "button",
                {
                  staticClass: "btn btn--primary",
                  on: { click: _vm.addSoundKitToCart },
                },
                [_vm._v("+ Add to cart")]
              ),
            ]),
            _c("div", [
              _c("h2", { staticClass: "h2" }, [
                _vm._v(_vm._s(_vm.soundkit.name)),
              ]),
              _c("p", { staticClass: "p" }, [
                _c("span", { staticStyle: { opacity: "0.6" } }, [
                  _vm._v("Sound Kit by "),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.producer.display_name))]),
              ]),
              _c("hr", { staticClass: "dividerCustom" }),
              _c("div", { staticClass: "flexbox h-mb-20" }, [
                _c(
                  "div",
                  {
                    staticClass: "audioDemo",
                    on: { click: _vm.togglePlayAudio },
                  },
                  [
                    _c("icon", {
                      attrs: {
                        name: !_vm.audioPlaying ? "play-full" : "pause-full",
                      },
                    }),
                  ],
                  1
                ),
                _c("p", [_vm._v("Demo Audio ")]),
              ]),
              _c("p", {
                staticClass: "p",
                domProps: { innerHTML: _vm._s(_vm.soundkit.description) },
              }),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }